var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectElectronCaptionMode, selectWasMutedBeforeKaraoke } from '../../selectors/ui';
import { sleep } from '../../utils';
import { getSearchValue } from '../../utils/http';
import { checkEndsWithPunctuation, KARAOKE_DELAY_MAPPING, KARAOKE_RESET_DELAY } from '../../utils/karaoke';
import * as segment from '../../utils/segment';
import { startRecording } from './audioV2';
export var ScribeCorrectionMode;
(function (ScribeCorrectionMode) {
    ScribeCorrectionMode["Unset"] = "";
    ScribeCorrectionMode["Fast"] = "fast";
    ScribeCorrectionMode["Accurate"] = "accurate";
})(ScribeCorrectionMode || (ScribeCorrectionMode = {}));
export const DESKTOP_THEMES = {
    oceanBlue: {
        floatingBackground: 'rgba(18, 36, 82, 0.80)',
        containerBackground: 'linear-gradient(118deg, rgba(23, 95, 161, 0.85) -2.39%, rgba(17, 28, 54, 0.85) 52.25%)',
        solidBackground: 'linear-gradient(118deg, rgb(54, 141, 221) -2.39%, rgba(18, 36, 82) 52.25%)',
        transparentBackground: 'linear-gradient(118deg, rgba(54, 141, 221, 0.60) -2.39%, rgba(18, 36, 82, 0.60) 52.25%)',
    },
    starryNight: {
        containerBackground: 'linear-gradient(118deg, rgba(36, 57, 73, 0.90) -2.39%, rgba(17, 28, 50, 0.90) 52.25%)',
        floatingBackground: 'rgba(42, 50, 69, 0.85)',
        solidBackground: 'linear-gradient(118deg, rgb(36, 57, 73) -2.39%, rgb(17, 28, 50) 52.25%)',
        transparentBackground: 'linear-gradient(118deg, rgba(36, 57, 73, 0.70) -2.39%, rgba(17, 28, 50, 0.70) 52.25%)',
    },
    fog: {
        containerBackground: 'linear-gradient(125deg, rgba(60, 64, 66, 0.90) 17.28%, rgba(46, 48, 49, 0.90) 55.43%)',
        solidBackground: 'linear-gradient(125deg, rgb(60, 64, 66) 17.28%, rgb(46, 48, 49) 55.43%)',
        transparentBackground: 'linear-gradient(125deg, rgba(60, 64, 66, 0.80) 17.28%, rgba(46, 48, 49, 0.80) 55.43%)',
        floatingBackground: 'rgba(50, 55, 58, 0.9)',
    },
    charcoal: {
        containerBackground: 'linear-gradient(125deg, rgba(41, 44, 46, 0.85) 17.28%, rgba(30, 32, 32, 0.85) 55.43%)',
        solidBackground: 'linear-gradient(125deg, rgb(41, 44, 46) 17.28%, rgb(30, 32, 32) 55.43%)',
        transparentBackground: 'linear-gradient(125deg, rgba(41, 44, 46, 0.90) 17.28%, rgba(30, 32, 32, 0.90) 55.43%)',
        floatingBackground: 'rgba(28, 30, 31, 0.9)',
    },
};
export var DesktopThemes;
(function (DesktopThemes) {
    DesktopThemes["oceanBlue"] = "oceanBlue";
    DesktopThemes["starryNight"] = "starryNight";
    DesktopThemes["fog"] = "fog";
    DesktopThemes["charcoal"] = "charcoal";
})(DesktopThemes || (DesktopThemes = {}));
export const ScribeCorrectionModeDefault = ScribeCorrectionMode.Fast;
const INITIAL_STATE = {
    loading: false,
    loadingReason: undefined,
    electronAppLoaded: false,
    sidebar: false,
    intercomOpen: false,
    isInviteCardOpen: false,
    readonly: getSearchValue(window, 'readonly', 'false') !== 'false',
    fontSize: 20,
    correctionMode: window.localStorage.getItem('scribe_correction_mode') || ScribeCorrectionMode.Unset,
    wordBeingEdited: false,
    fullScreen: false,
    displayShortcuts: false,
    insertSpeakerNameMode: false,
    electronCaptionMode: false,
    keyboardInputShown: false,
    isConnectToMeetingsOpen: false,
    karaokeState: {
        wordIndex: 0,
        transcriptId: '',
    },
    shouldHighlightConnectToMeetings: false,
    openTauriAttempted: false,
    isOneClickScribeModalOpen: false,
    desktopTheme: window.localStorage.getItem('desktop_theme') || DesktopThemes.oceanBlue,
};
const uiState = createSlice({
    name: 'uiState',
    initialState: INITIAL_STATE,
    reducers: {
        scribeSetFontSize: (state, { payload }) => {
            state.fontSize = payload;
        },
        setDisplayShortcuts: (state, { payload }) => {
            state.displayShortcuts = payload;
        },
        setScribeCorrectionMode: (state, { payload }) => {
            window.localStorage.setItem('scribe_correction_mode', payload);
            segment.track('Web - Correction Mode - Mode Changed', {
                mode: payload,
            });
            state.correctionMode = payload;
        },
        setIsWordBeingEdited: (state, { payload }) => {
            state.wordBeingEdited = payload;
        },
        setFullScreen: (state, { payload }) => {
            state.fullScreen = payload;
        },
        internalSetElectronCaptionMode: (state, { payload }) => {
            state.electronCaptionMode = payload;
        },
        setInsertSpeakerNameMode: (state, { payload }) => {
            state.insertSpeakerNameMode = payload;
            state.displayShortcuts = payload;
        },
        setElectronAppLoaded: (state, { payload }) => {
            state.electronAppLoaded = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload.isLoading;
            if (payload.loadingReason) {
                state.loadingReason = payload.loadingReason;
            }
        },
        setIsInviteCardOpen: (state, { payload }) => {
            state.isInviteCardOpen = payload;
        },
        toggleSideBar: (state, { payload }) => {
            if (state.sidebar === payload)
                return state;
            state.sidebar = !state.sidebar;
        },
        cleanUpUi: () => {
            return INITIAL_STATE;
        },
        setIntercomOpen: (state, { payload }) => {
            state.intercomOpen = payload;
        },
        setKeyboardInputShown: (state, { payload }) => {
            state.keyboardInputShown = payload;
        },
        setConnectToMeetingsOpen: (state, { payload }) => {
            state.isConnectToMeetingsOpen = payload;
        },
        setKaraokeState: (state, { payload }) => {
            state.karaokeState = payload;
        },
        resetKaraokeState: (state) => {
            state.karaokeState = { wordIndex: 0, transcriptId: '' };
        },
        setWasMutedBeforeKaraoke(state, { payload }) {
            state.wasMutedBeforeKaraoke = payload;
        },
        setShouldHighlightConnectToMeetings: (state, { payload }) => {
            state.shouldHighlightConnectToMeetings = payload;
        },
        setOpenTauriAttempted: (state, { payload }) => {
            state.openTauriAttempted = payload;
        },
        setIsOneClickScribeModalOpen: (state, { payload }) => {
            state.isOneClickScribeModalOpen = payload;
        },
        setDesktopTheme: (state, { payload }) => {
            window.localStorage.setItem('desktop_theme', payload);
            state.desktopTheme = payload;
        },
    },
});
export const uiStateReducer = uiState.reducer;
export const { setDisplayShortcuts, scribeSetFontSize, setScribeCorrectionMode, setFullScreen, setInsertSpeakerNameMode, setElectronAppLoaded, setLoading, setIsInviteCardOpen, toggleSideBar, cleanUpUi, setIntercomOpen, setIsWordBeingEdited, setConnectToMeetingsOpen, setKaraokeState, resetKaraokeState, setShouldHighlightConnectToMeetings, setOpenTauriAttempted, setWasMutedBeforeKaraoke, setIsOneClickScribeModalOpen, setDesktopTheme, } = uiState.actions;
const { setKeyboardInputShown, internalSetElectronCaptionMode } = uiState.actions;
export const updateKeyboardInputShown = createAsyncThunk('ui/updateKeyboardInputShown', (keyboardInputShown, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    // TODO: if muted -> keyboardInputShown false
    // TODO: On Room status update:
    dispatch(setKeyboardInputShown(keyboardInputShown));
    if (selectElectronCaptionMode(state)) {
        (_a = window.electronIPC) === null || _a === void 0 ? void 0 : _a.sendCCMode('keyboard', keyboardInputShown);
    }
}));
export const setElectronCaptionMode = createAsyncThunk('ui/setElectronCaptionMode', (electronCaptionMode, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const dispatch = thunkAPI.dispatch;
    (_b = window.electronIPC) === null || _b === void 0 ? void 0 : _b.sendSetInCaptionMode(electronCaptionMode);
    dispatch(internalSetElectronCaptionMode(electronCaptionMode));
}));
if (window.electronIPC) {
    window.electronIPC.onSetElectronCaptionMode((on) => {
        if (on) {
            //@ts-ignore
            window.navigate('/cc/conversation');
        }
        else {
            //@ts-ignore
            window.navigate('/web/transcript');
        }
    });
}
/**
 * due to https://linear.app/weareava/issue/WEB-2004/remove-karaoke-until-the-api-gets-better we are removing the karaoke effect
 * but keeping its ability to "time" the length of audio to be played for muting and unmuting purposes
 * it is important to note that this is a temporary solution and will be changed/removed once the API is updated
 */
export const performKaraoke = createAsyncThunk('uiState/karaoke', ({ voice, language, transcriptId, wordCount, content, }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    for (let currentWordIndex = 0; currentWordIndex < wordCount; currentWordIndex++) {
        const currentWord = content[currentWordIndex];
        const { isPunctuation, punctuationDelay } = checkEndsWithPunctuation(currentWord, voice, language);
        const karaokeDelay = KARAOKE_DELAY_MAPPING[voice];
        yield sleep(karaokeDelay);
        dispatch(setKaraokeState({ transcriptId, wordIndex: currentWordIndex }));
        if (isPunctuation) {
            yield sleep(punctuationDelay);
        }
    }
    yield sleep(KARAOKE_RESET_DELAY);
    dispatch(resetKaraokeState());
    const state = getState();
    if (!selectWasMutedBeforeKaraoke(state)) {
        dispatch(startRecording());
    }
}));
